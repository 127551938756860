<template>
  <div class="aboutUs">
    <mt-header :title="$t('aboutUs')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)">
          <img src="@/assets/back.png" height="20" width="20" slot="icon">
          {{ $t('back') }}
        </mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="comtent">
      <img src="../../assets/aboutUsBg.png" width="100%">
      <div class="box">
        <div class="title">
          <img src="../../assets/about_us.png" width="21px">
          What’s PrestaYa?
        </div>
        <div class="value">
          No money to spend, find your Internet wallet PrestaYa, let your loan carefree. PrestaYa is a fast and convenient mobile phone loan tool. You only need to provide "ID card" and download and install app to experience the Internet financial loan service of "stay at home, apply for efficient approval immediately, and reuse"; it can meet young people's consumption demand of "anytime, anywhere, want to buy" and improve the quality of life.
        </div>
      </div>
      <div class="box">
        <div class="title">
          <img src="../../assets/contact-us.png" width="21px">
          Contact us
        </div>
        <div class="value">
          If you want to cooperate with us or have questions in use, please contact our customer service. Customer service email: {{ email }}
        </div>
      </div>
      <div class="box">
        <div class="title">
          <img src="../../assets/join-us.png" width="21px">
          Join us
        </div>
        <div class="value">
          If you want to join us, please send your resume and we will contact you as soon as possible. Recruitment email: {{ email }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { mapState } from "vuex";
export default {
  name: 'AboutUs',
  components: {
    callPhone,
  },
  computed: {
    ...mapState(['brand']),
    email() {
      if (this.brand.serviceInfo && this.brand.serviceInfo.email) {
        return this.brand.serviceInfo.email
      }
      return ''
    }
  },
}
</script>

<style lang="scss" scoped>
.aboutUs {
  > .comtent {
    padding: 22px 18px;
    .box {
      margin-top: 22px;
      .title {
        font-size: 15px;
        font-weight: 500;
        color: #212121;
        img {
          margin-right: 4px;
        }
      }
      .value {
        margin-top: 10px;
        font-size: 14px;
        color: #757575;
        line-height: 24px;
      }
    }
  }
}
</style>